import 'bootstrap';
import dictionary from '../../../translations/dictionary';
import Button from '../utilities/button';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import SearchCorpusBox from '../components/searchcorpusbox';
import { TableHeaderRow } from '../constants/admintable';
import { getSearchesMade } from '../utilities/functions';

class SearchLogs extends PureComponent {
    static propTypes = {
		languageFromMain: PropTypes.string.isRequired,
	}

    constructor(props) {
		super(props);
		this.state = {
			searchLogs: [],
			reversed: false,
			sortedByUser: false,
			sortedBySearch: false,
			refreshCount: 0,
		}
	}

	componentDidMount() {
		this.getSearchLogs();
	}

	getSearchLogs = () => {
		getSearchesMade()
		.then(result => {
			this.setState({
				searchLogs: result
			});
		});
	}

	timeParse = parse => {
		var time = parse;
		if (time != null || time != undefined) {
			var date = time.substring(0, time.indexOf("T"));
			var timeOfDay = time.substring(time.indexOf("T") + 1, time.indexOf("T")+9);
			var result = date + " " + timeOfDay
		}
		return result;
	}

	handleChange = event => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value})
	}

	searchQuery = query => {
		query = query.toLowerCase();
		let table, tr, td, i, j, found;
		table = document.getElementById("searchLogList");
		tr = table.getElementsByTagName("tr");

		// Loop through all table rows, and hide those who don't match the search query
		for (i = 0; i < tr.length; i++) {
			td = tr[i].getElementsByTagName("td");
			for (j = 0; j < td.length; j++) {
				if (td[j].innerHTML.toLowerCase().indexOf(query) > -1) {
					found = true;
				}
			}
			if (found) {
				tr[i].style.display = "";
				found = false;
			} else {
				tr[i].style.display = "none";
			}
		}
	}

	sortSearches = () => {
		var table = this.state.searchLogs;
		if(this.state.sortedBySearch === false){
			var sorted = table.slice(0).sort((a, b) => a.username.localeCompare(b.username))
			this.setState({
				searchLogs: sorted,
				sortedBySearch: true
			})
		} else {
			var sortedBack = table.slice(0).sort((a, b) => b.username.localeCompare(a.username))
			this.setState({
				searchLogs: sortedBack,
				sortedBySearch: false
			})
		}
	}

	sortUsers = () => {
		var table = this.state.searchLogs;
		if(this.state.sortedByUser === false){
			var sorted = table.slice(0).sort((a, b) => a.username.localeCompare(b.username))
			this.setState({
				searchLogs: sorted,
				sortedByUser: true
			})
		} else {
			var sortedBack = table.slice(0).sort((a, b) => b.username.localeCompare(a.username))
			this.setState({
				searchLogs: sortedBack,
				sortedByUser: false
			})
		}
	}

	reverseOrder = () => {
		var table = this.state.searchLogs;
		if(this.state.reversed === false){
			var sorted = table.slice(0).sort((a, b) => a.createdAt.localeCompare(b.createdAt))
			this.setState({
				searchLogs: sorted,
				reversed: true
			})
		} else {
			var sortedBack = table.slice(0).sort((a, b) => b.createdAt.localeCompare(a.createdAt))
			this.setState({
				searchLogs: sortedBack,
				reversed: false
			})
		}
	}

	tableRender = () => {
		return (
		<tbody id='searchLogList'>
			{this.state.searchLogs.map((item, key) => {
				return (
					<tr key = {key}>
						<td>{this.timeParse(item.createdAt)}</td>
						<td>{item.username}</td>
						<td>{item.query}</td>
						<td>{item.corporas.map(corpora => corpora + " ")}</td>
					</tr>
					)
				})
			}
		</tbody>
		);
	}

	render() {
		return (
			<div id='container' data-testid='search-logs'>
				<div className='top-gap'></div>
				<div id="buttonContainer" >
					<div id="orderButtonByUsers">
						<Button
							label={dictionary[this.props.languageFromMain].common.orderReversalSearch}
							uiType='button float-right'
							onClick={this.sortSearches}
						/>
					</div>
					<div id="orderButtonByUsers">
						<Button
							label={dictionary[this.props.languageFromMain].common.orderReversalUsers}
							uiType='button float-right'
							onClick={this.sortUsers}
						/>
					</div>
					<div id="orderButtonTimeReverse">
						<Button
							label={dictionary[this.props.languageFromMain].common.orderReversalTime}
							uiType='button float-right'
							onClick={this.reverseOrder}
						/>
					</div>
					<div id='searchBox'>
						<SearchCorpusBox
							search={this.searchQuery}
							placeholder={dictionary[this.props.languageFromMain].common.search}
						/>
					</div>
				</div>
				<table className='table table-striped'>
					<thead className='thead-blue'>
						<tr>
							<TableHeaderRow headers={dictionary[this.props.languageFromMain].searchlogs.headers} />
						</tr>
					</thead>
					{this.tableRender()}
				</table>
				<div className='bottom-gap'></div>
			</div>
		)
	}
}

export default SearchLogs;